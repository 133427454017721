import { local } from "@/request/http";
/**
 * @name: 获取中心菜单列表
 * @author: camellia
 * @date: 2021-04-21
 */
export const getCenterMenuList = () =>
  local([
    //  {
    //    id: 1,
    //    path: "teacher/index",
    //    icon: "el-icon-message",
    //    alias: "首页",
    //    is_show: true,
    //  },
    {
      id: 2,
      path: "teacher/calendar",
      icon: "el-icon-message",
      alias: "日程",
      is_show: true,
    },
    {
      id: 110,
      path: "teacher/course",
      icon: "el-icon-message",
      alias: "课程",
      is_show: true,
    },
    {
      id: 100,
      path: "teacher/projectList",
      icon: "el-icon-message",
      alias: "项目",
      is_show: true,
    },

    {
      id: 4,
      path: "teacher/groupList",
      icon: "el-icon-message",
      alias: "班级",
      is_show: true,
    },
    {
      id: 50,
      path: "teacher/questionBank",
      icon: "el-icon-message",
      alias: "题库",
      is_show: true,
    },
    {
      id: 51,
      path: "teacher/questionBankUpdate",
      icon: "el-icon-message",
      alias: "编辑题库",
      is_show: false,
    },
    {
      id: 60,
      path: "teacher/articleList",
      icon: "el-icon-message",
      alias: "资源",
      is_show: true,
    },
    {
      id: 7,
      path: "teacher/report/reportTemplate",
      alias: "报告",
      is_show: true,
      //  childs: [
      //    {
      //      id: 71,
      //      path: "reportCorrection",
      //      alias: "评阅",
      //      is_show: false,
      //    },
      //    {
      //      id: 72,
      //      path: "reportTemplate",
      //      alias: "模板",
      //      is_show: true,
      //    },
      //    {
      //      id: 73,
      //      path: "pdfDownload",
      //      icon: "el-icon-message",
      //      alias: "PDF下载",
      //      is_show: true,
      //    },
      //  ],
    },
    {
      id: 3,
      path: "teacher/center",
      alias: "中心",
      is_show: true,
      childs: [
        {
          id: 31,
          path: "webPageContent",
          alias: "网站内容",
          is_show: true,
        },
        {
          id: 33,
          path: "webPageUpdate",
          alias: "编辑网站内容",
          is_show: false,
        },
        {
          id: 32,
          path: "webNewsList",
          alias: "网站新闻",
          is_show: true,
        },
        {
          id: 34,
          path: "webNewsUpdate",
          alias: "编辑网站新闻",
          is_show: false,
        },
        {
          id: 35,
          path: "webNoticeList",
          alias: "网站公告",
          is_show: true,
        },
        {
          id: 36,
          path: "webNoticeUpdate",
          alias: "编辑网站公告",
          is_show: false,
        },
        {
          id: 37,
          path: "memberList",
          alias: "成员管理",
          is_show: true,
        },
        {
          id: 38,
          path: "courseList",
          alias: "课程列表",
          is_show: true,
        },
        {
          id: 39,
          path: "openList",
          alias: "开放单元",
          is_show: true,
        },
        // {
        //   id: 391,
        //   path: "statistics",
        //   alias: "信息统计",
        //   is_show: true,
        // },
      ],
    },
    {
      id: 8,
      path: "teacher/other",
      icon: "el-icon-message",
      alias: "其他",
      is_show: true,
      childs: [
        {
          id: 81,
          path: "colleague",
          alias: "同事管理",
          is_show: true,
        },
        {
          id: 82,
          path: "workStatPage",
          alias: "工作量统计",
          is_show: true,
        },
        {
          id: 83,
          path: "searchScore",
          alias: "成绩查询",
          is_show: true,
        },
        {
          id: 84,
          path: "fenxi",
          alias: "成绩分析",
          is_show: true,
        },
        {
          id: 85,
          path: "xueqing",
          alias: "学情分析",
          is_show: true,
        },
        {
          id: 86,
          path: "tongji",
          alias: "开课统计",
          is_show: true,
        },
      ],
    },
    {
      id: 11,
      path: "student/reportList",
      icon: "el-icon-message",
      alias: "作业",
      is_show: true,
    },

    /*{
            id: 9,
            path: "teacher/help",
            icon: "el-icon-message",
            alias: "帮助",
            is_show: true,
          },//*/
  ]);

/**
 * @name: 获取普通教师菜单
 * @author: camellia
 * @date: 2021-04-21
 */
export const getTeacherMenuList = () =>
  local([
    //  {
    //    id: 1,
    //    path: "teacher/index",
    //    icon: "el-icon-message",
    //    alias: "首页",
    //    is_show: true,
    //  },
    {
      id: 2,
      path: "teacher/calendar",
      icon: "el-icon-message",
      alias: "日程",
      is_show: true,
    },
    {
      id: 110,
      path: "teacher/course",
      icon: "el-icon-message",
      alias: "课程",
      is_show: true,
    },
    {
      id: 100,
      path: "teacher/projectList",
      icon: "el-icon-message",
      alias: "项目",
      is_show: true,
    },
    {
      id: 4,
      path: "teacher/groupList",
      icon: "el-icon-message",
      alias: "班级",
      is_show: true,
    },
    {
      id: 50,
      path: "teacher/questionBank",
      icon: "el-icon-message",
      alias: "题库",
      is_show: true,
    },
    {
      id: 51,
      path: "teacher/questionBankUpdate",
      icon: "el-icon-message",
      alias: "编辑题库",
      is_show: false,
    },
    {
      id: 60,
      path: "teacher/articleList",
      icon: "el-icon-message",
      alias: "资源",
      is_show: true,
    },
    {
      id: 7,
      path: "teacher/report/reportTemplate",
      alias: "报告",
      is_show: true,
    },
    // {
    //   id: 11,
    //   path: "teacher/other/navigation",
    //   icon: "el-icon-message",
    //   alias: "其他",
    //   is_show: true,
    // },
    {
      id: 11,
      path: "teacher/other",
      icon: "el-icon-message",
      alias: "其他",
      is_show: true,
      childs: [
        {
          id: 81,
          path: "colleague",
          alias: "同事管理",
          is_show: true,
        },
        {
          id: 82,
          path: "workStatPage",
          alias: "工作量统计",
          is_show: true,
        },
        {
          id: 83,
          path: "searchScore",
          alias: "成绩查询",
          is_show: true,
        },
        {
          id: 84,
          path: "fenxi",
          alias: "成绩分析",
          is_show: true,
        },
        {
          id: 85,
          path: "xueqing",
          alias: "学情分析",
          is_show: true,
        },
        {
          id: 86,
          path: "tongji",
          alias: "开课统计",
          is_show: true,
        },
      ],
    },
    {
      id: 12,
      path: "student/reportList",
      icon: "el-icon-message",
      alias: "作业",
      is_show: true,
    },
    /*{
            id: 9,
            path: "teacher/help",
            icon: "el-icon-message",
            alias: "帮助",
            is_show: true,
          },//*/
  ]);

/**
 * @name: 获取admin菜单
 * @author: camellia
 * @date: 2021-04-21
 */
export const getAdminMenuList = () =>
  local([
    //  {
    //    id: 1,
    //    path: "teacher/index",
    //    icon: "el-icon-message",
    //    alias: "首页",
    //    is_show: true,
    //  },
    {
      id: 2,
      path: "teacher/calendar",
      icon: "el-icon-message",
      alias: "日程",
      is_show: true,
    },
    {
      id: 110,
      path: "teacher/course",
      icon: "el-icon-message",
      alias: "课程",
      is_show: true,
    },
    {
      id: 100,
      path: "teacher/projectList",
      icon: "el-icon-message",
      alias: "项目",
      is_show: true,
    },
    {
      id: 4,
      path: "teacher/groupList",
      icon: "el-icon-message",
      alias: "班级",
      is_show: true,
    },
    {
      id: 50,
      path: "teacher/questionBank",
      icon: "el-icon-message",
      alias: "题库",
      is_show: true,
    },
    {
      id: 51,
      path: "teacher/questionBankUpdate",
      icon: "el-icon-message",
      alias: "编辑题库",
      is_show: false,
    },
    {
      id: 60,
      path: "teacher/articleList",
      icon: "el-icon-message",
      alias: "资源",
      is_show: true,
    },
    {
      id: 7,
      path: "teacher/report/reportTemplate",
      alias: "报告",
      is_show: true,
      //  childs: [
      //    {
      //      id: 71,
      //      path: "reportCorrection",
      //      alias: "评阅",
      //      is_show: false,
      //    },
      //    {
      //      id: 72,
      //      path: "reportTemplate",
      //      alias: "模板",
      //      is_show: true,
      //    },
      //    {
      //      id: 73,
      //      path: "pdfDownload",
      //      icon: "el-icon-message",
      //      alias: "PDF下载",
      //      is_show: true,
      //    },
      //  ],
    },
    {
      id: 3,
      path: "teacher/center",
      alias: "中心",
      is_show: true,
      childs: [
        {
          id: 31,
          path: "webPageContent",
          alias: "网站内容",
          is_show: true,
        },
        {
          id: 33,
          path: "webPageUpdate",
          alias: "编辑网站内容",
          is_show: false,
        },
        {
          id: 32,
          path: "webNewsList",
          alias: "网站新闻",
          is_show: true,
        },
        {
          id: 34,
          path: "webNewsUpdate",
          alias: "编辑网站新闻",
          is_show: false,
        },
        {
          id: 35,
          path: "webNoticeList",
          alias: "网站公告",
          is_show: true,
        },
        {
          id: 36,
          path: "webNoticeUpdate",
          alias: "编辑网站公告",
          is_show: false,
        },
        {
          id: 37,
          path: "memberList",
          alias: "成员管理",
          is_show: true,
        },
        {
          id: 38,
          path: "courseList",
          alias: "课程列表",
          is_show: true,
        },
        {
          id: 39,
          path: "openList",
          alias: "开放单元",
          is_show: true,
        },
        // {
        //   id: 391,
        //   path: "statistics",
        //   alias: "信息统计",
        //   is_show: true,
        // },
      ],
    },
    // {
    //   id: 11,
    //   path: "teacher/other/navigation",
    //   icon: "el-icon-message",
    //   alias: "其他",
    //   is_show: true,
    // },
    {
      id: 11,
      path: "teacher/other",
      icon: "el-icon-message",
      alias: "其他",
      is_show: true,
      childs: [
        {
          id: 81,
          path: "colleague",
          alias: "同事管理",
          is_show: true,
        },
        {
          id: 82,
          path: "workStatPage",
          alias: "工作量统计",
          is_show: true,
        },
        {
          id: 83,
          path: "searchScore",
          alias: "成绩查询",
          is_show: true,
        },
        {
          id: 84,
          path: "fenxi",
          alias: "成绩分析",
          is_show: true,
        },
        {
          id: 85,
          path: "xueqing",
          alias: "学情分析",
          is_show: true,
        },
        {
          id: 86,
          path: "tongji",
          alias: "开课统计",
          is_show: true,
        },
      ],
    },
    {
      id: 8,
      path: "student/reportList",
      icon: "el-icon-message",
      alias: "作业",
      is_show: true,
    },
    // {
    //   id: 13,
    //   path: "teacher/developer",
    //   alias: "开发者",
    //   is_show: true,
    //   childs: [
    //     {
    //       id: 1301,
    //       path: "personalInfo",
    //       alias: "个人信息",
    //       is_show: true,
    //     },
    //     {
    //       id: 1302,
    //       path: "virtualExp",
    //       alias: "虚拟实验",
    //       is_show: true,
    //     },
    //   ],
    // },
    /*{
            id: 9,
            path: "teacher/help",
            icon: "el-icon-message",
            alias: "帮助",
            is_show: true,
          },//*/
  ]);

/**
 * @name: 获取学生菜单
 * @author: camellia
 * @date: 2021-04-21
 */
export const getStudentMenuList = () =>
  local([
    {
      id: 1,
      path: "student/reportList",
      icon: "el-icon-message",
      alias: "报告列表",
      is_show: true,
    },
  ]);

/**
 * @name: 获取中心管理员菜单
 * @author: camellia
 * @email: guanchao_gc@qq.com
 */
export const getCerterMenuList = () =>
  local([
    {
      id: 3,
      path: "teacher/center",
      alias: "中心",
      is_show: true,
      childs: [
        {
          id: 31,
          path: "webPageContent",
          alias: "网站内容",
          is_show: true,
        },
        {
          id: 33,
          path: "webPageUpdate",
          alias: "编辑网站内容",
          is_show: false,
        },
        {
          id: 32,
          path: "webNewsList",
          alias: "网站新闻",
          is_show: true,
        },
        {
          id: 34,
          path: "webNewsUpdate",
          alias: "编辑网站新闻",
          is_show: false,
        },
        {
          id: 35,
          path: "webNoticeList",
          alias: "网站公告",
          is_show: true,
        },
        {
          id: 36,
          path: "webNoticeUpdate",
          alias: "编辑网站公告",
          is_show: false,
        },
        {
          id: 37,
          path: "memberList",
          alias: "成员管理",
          is_show: true,
        },
        {
          id: 38,
          path: "courseList",
          alias: "课程列表",
          is_show: true,
        },
        {
          id: 39,
          path: "openList",
          alias: "开放单元",
          is_show: true,
        },
        // {
        //   id: 391,
        //   path: "statistics",
        //   alias: "信息统计",
        //   is_show: true,
        // },
      ],
    },
  ]);

/**
 * @name: 获取安全管理员菜单
 */
export const getSafeAdminMenuList = () =>
  local([
    {
      id: 1,
      path: "manager/logList",
      icon: "el-icon-message",
      alias: "日志列表",
      is_show: true,
    },
  ]);
  
/**
 * @name: 获取开发者菜单
 * @author: LXY
 */
export const getdeveloperMenuList = () =>
local([
  //  {
  //    id: 1,
  //    path: "teacher/index",
  //    icon: "el-icon-message",
  //    alias: "首页",
  //    is_show: true,
  //  },
  {
    id: 2,
    path: "teacher/calendar",
    icon: "el-icon-message",
    alias: "日程",
    is_show: true,
  },
  {
    id: 110,
    path: "teacher/course",
    icon: "el-icon-message",
    alias: "课程",
    is_show: true,
  },
  {
    id: 100,
    path: "teacher/projectList",
    icon: "el-icon-message",
    alias: "项目",
    is_show: true,
  },
  {
    id: 4,
    path: "teacher/groupList",
    icon: "el-icon-message",
    alias: "班级",
    is_show: true,
  },
  {
    id: 50,
    path: "teacher/questionBank",
    icon: "el-icon-message",
    alias: "题库",
    is_show: true,
  },
  {
    id: 51,
    path: "teacher/questionBankUpdate",
    icon: "el-icon-message",
    alias: "编辑题库",
    is_show: false,
  },
  {
    id: 60,
    path: "teacher/articleList",
    icon: "el-icon-message",
    alias: "资源",
    is_show: true,
  },
  {
    id: 7,
    path: "teacher/report/reportTemplate",
    alias: "报告",
    is_show: true,
  },
  {
    id: 3,
    path: "teacher/center",
    alias: "中心",
    is_show: true,
    childs: [
      {
        id: 31,
        path: "webPageContent",
        alias: "网站内容",
        is_show: true,
      },
      {
        id: 33,
        path: "webPageUpdate",
        alias: "编辑网站内容",
        is_show: false,
      },
      {
        id: 32,
        path: "webNewsList",
        alias: "网站新闻",
        is_show: true,
      },
      {
        id: 34,
        path: "webNewsUpdate",
        alias: "编辑网站新闻",
        is_show: false,
      },
      {
        id: 35,
        path: "webNoticeList",
        alias: "网站公告",
        is_show: true,
      },
      {
        id: 36,
        path: "webNoticeUpdate",
        alias: "编辑网站公告",
        is_show: false,
      },
      {
        id: 37,
        path: "memberList",
        alias: "成员管理",
        is_show: true,
      },
      {
        id: 38,
        path: "courseList",
        alias: "课程列表",
        is_show: true,
      },
      {
        id: 39,
        path: "openList",
        alias: "开放单元",
        is_show: true,
      },
      // {
      //   id: 391,
      //   path: "statistics",
      //   alias: "信息统计",
      //   is_show: true,
      // },
    ],
  },
  // {
  //   id: 11,
  //   path: "teacher/other/navigation",
  //   icon: "el-icon-message",
  //   alias: "其他",
  //   is_show: true,
  // },
  {
    id: 11,
    path: "teacher/other",
    icon: "el-icon-message",
    alias: "其他",
    is_show: true,
    childs: [
      {
        id: 81,
        path: "colleague",
        alias: "同事管理",
        is_show: true,
      },
      {
        id: 82,
        path: "workStatPage",
        alias: "工作量统计",
        is_show: true,
      },
      {
        id: 83,
        path: "searchScore",
        alias: "成绩查询",
        is_show: true,
      },
      {
        id: 84,
        path: "fenxi",
        alias: "成绩分析",
        is_show: true,
      },
      {
        id: 85,
        path: "xueqing",
        alias: "学情分析",
        is_show: true,
      },
      {
        id: 86,
        path: "tongji",
        alias: "开课统计",
        is_show: true,
      },
    ],
  },
  {
    id: 8,
    path: "student/reportList",
    icon: "el-icon-message",
    alias: "作业",
    is_show: true,
  },
  {
    id: 13,
    path: "teacher/developer/virtualExp",
    alias: "开发者",
    is_show: true,
    // childs: [
      // {
      //   id: 1301,
      //   path: "personalInfo",
      //   alias: "个人信息",
      //   is_show: true,
      // },
      // {
      //   id: 1302,
      //   path: "virtualExp",
      //   alias: "虚拟实验",
      //   is_show: true,
      // },
    // ],
  },
  /*{
          id: 9,
          path: "teacher/help",
          icon: "el-icon-message",
          alias: "帮助",
          is_show: true,
        },//*/
]);


export const getSchoolAdminMenuList = () =>
local([
  {
    id: 2,
    path: "schoolAdmin/center",
    icon: "el-icon-message",
    alias: "中心管理",
    is_show: true,
  },
  {
    id: 1,
    path: "schoolAdmin/calendar",
    icon: "el-icon-message",
    alias: "日程管理",
    is_show: true,
  },
  {
    id: 3,
    path: "schoolAdmin/teacher",
    icon: "el-icon-message",
    alias: "教师管理",
    is_show: true,
  },
  {
    id: 4,
    path: "schoolAdmin/exp",
    icon: "el-icon-message",
    alias: "虚拟实验",
    is_show: true,
  },
]);


export const getDYMenuList = () =>
  local([
    {
      id: 1,
      path: "DY/index",
      icon: "el-icon-message",
      alias: "首页",
      is_show: true,
    },
    {
      id: 2,
      path: "DY/zonglan",
      icon: "el-icon-message",
      alias: "用户总览",
      is_show: true,
    },
    {
      id: 3,
      path: "DY/fenxi",
      icon: "el-icon-message",
      alias: "成绩分析",
      is_show: true,
    },
    {
      id: 4,
      path: "DY/xueqing",
      icon: "el-icon-message",
      alias: "学情分析",
      is_show: true,
    },
    {
      id: 5,
      path: "DY/tongji",
      icon: "el-icon-message",
      alias: "开课统计",
      is_show: true,
    },
  ]);
