<template>
  <el-container class="layout">
    <el-aside width="9.1667vw" :style="{display:(hideMenu == 1?'none':'')}">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        text-color="#707070"
        active-text-color="#0D5570"
        @select="handleSelect"
      >
        <div class="personal-header-border">
          <!-- 企业图标 -->
          <div class="company-logo-div">
            <img src="../assets/image/moolsnetlogo.png" class="width110" />
          </div>
        </div>
        <NavMenu :navMenus="menuData" />
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
      <!-- 管理员菜单 -->
        <div class="header-name" v-if="userinfo.userType == 9 && showmanagerNav">
          <div>
            <router-link :to="{name:'manager/logList'}">
              <div class="operatorItem floatLeft">日志管理</div>
            </router-link>
            <router-link :to="{name:'apply'}">
              <div class="operatorItem floatLeft">申请管理</div>
            </router-link>
            <router-link :to="{name:'schoolManager'}">
              <div class="operatorItem floatLeft">学校管理</div>
            </router-link>
            <!-- <router-link :to="{name:'userManager'}">
              <div class="operatorItem floatLeft">用户管理</div>
            </router-link> -->
            <el-dropdown placement="bottom-start">
              <span class="el-dropdown-link">
                <div class="operatorItem floatLeft">
                  用户管理
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link :to="{name:'userManager'}">
                    <div class="operatorItem floatLeft">用户管理</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{name:'userRepeat'}">
                    <div class="operatorItem floatLeft">学号重复</div>
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown placement="bottom-start">
              <span class="el-dropdown-link">
                <div class="operatorItem floatLeft">
                  意见反馈
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link :to="{name:'feedBack'}">
                    <div class="operatorItem floatLeft">意见反馈</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{name:'keyWords'}">
                    <div class="operatorItem floatLeft">关键词库</div>
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <router-link :to="{name:'cert'}">
              <div class="operatorItem floatLeft">证书管理</div>
            </router-link>

            <router-link :to="{name:'trialApplication'}">
              <div class="operatorItem floatLeft">试用申请</div>
            </router-link>
            <router-link :to="{name:'loginTeacher'}">
              <div class="operatorItem floatLeft">登录教师账号</div>
            </router-link>
            <el-dropdown placement="bottom-start">
              <span class="el-dropdown-link">
                <div class="operatorItem floatLeft">
                  虚拟实验
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link :to="{name:'developerList'}">
                    <div class="operatorItem floatLeft">开发者</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{name:'editorWeb'}">
                    <div class="operatorItem floatLeft">编辑申报网站</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{name:'expManager'}">
                    <div class="operatorItem floatLeft">虚拟实验管理</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{name:'labManager'}">
                    <div class="operatorItem floatLeft">虚拟实验分类管理</div>
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <router-link :to="{name:'task'}">
              <div class="operatorItem floatLeft">项目进度管理</div>
            </router-link>

            <router-link :to="{name:'openCourse'}">
              <div class="operatorItem floatLeft">慕课课程</div>
            </router-link>

            <router-link :to="{name:'center'}">
              <div class="operatorItem floatLeft">中心管理</div>
            </router-link>

            <el-dropdown placement="bottom-start">
              <span class="el-dropdown-link">
                <div class="operatorItem floatLeft">
                  其他
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link :to="{name:'exportgameData'}">
                    <div class="operatorItem floatLeft">导出小游戏数据</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{name:'batchCreate'}">
                    <div class="operatorItem floatLeft">批量创建课程项目群组</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{name:'sendallTeacher'}">
                    <div class="operatorItem floatLeft">给全体老师发送通告</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{name:'exportExamPage'}">
                    <div class="operatorItem floatLeft">导出测试试卷</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{name:'exportPDF'}">
                    <div class="operatorItem floatLeft">生成报告PDF</div>
                  </router-link>
                </el-dropdown-item>
                <!-- <el-dropdown-item>
                    <div class="operatorItem floatLeft" @click="createReportPDF">生成报告PDF</div>
                </el-dropdown-item> -->
              </el-dropdown-menu>
              
            </el-dropdown>
          </div>
        </div>
        <div class="header-name schoolHead" v-else>
          <!-- 学校logo -->
          <img v-if="hideMenu != 1" class="schoolLogo" :src="userinfo.schoolLogo" alt="">
          <!-- 学校名称 -->
          <div :class="type == 1?'showbackButton school_text':'school_text'">{{hideMenu == 1 ? open_name : userinfo.school_name}}</div>
        </div>
        <div class="right">
          <div class="userAvator" @click="setPersonalInfo()">
            <img :src="userinfo.wx_img" class="personal-logo" />
          </div>
          <!-- 用户信息 -->
          <div class="userInfo">
            <p class="info">{{userinfo.username}} {{userinfo.personal_id}}</p>
            <i class="el-icon-arrow-down" @click="isExit"></i>

            <div class="exit">
              <el-button plain class="logout" @click="logout()">退出</el-button>
            </div>
            <i class="el-icon-arrow-up" @click="isExit"></i>
          </div>
        </div>
      </el-header>
      <!-- <el-collapse v-model="activeNames" @change="handleChange" v-if="userinfo.userType == 9">
                    <el-collapse-item title="管理菜单">
                            <router-link :to="{name:'manager/logList'}">
                                <div class="operatorItem floatLeft">日志管理</div>
                            </router-link> 
                        <div class="operatorItem floatLeft" >学校管理</div> 
                    </el-collapse-item> 
      </el-collapse>-->
      <el-main>
        <!-- <el-breadcrumb separator="/">
                    <el-breadcrumb-item>
                        <a href="/">首页</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                        <a href="/manager">管理平台</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item v-for="item in menu" :key="item.id">
                        {{ item.alias }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
        <br />-->
        <router-view />
      </el-main>
    </el-container>
    <!-- <el-container>
            <el-aside width="10.9375vw">
                <el-menu
                    :default-active="defaultActive"
                    class="el-menu-vertical-demo"
                    background-color="#333"
                    text-color="#fff"
                    active-text-color="#ffd04b"
                    @select="handleSelect"
                >
                    <NavMenu :navMenus="menuData" />
                </el-menu>
            </el-aside>
            <el-main>
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>
                        <a href="/">首页</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                        <a href="/manager">管理平台</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item v-for="item in menu" :key="item.id">
                        {{ item.alias }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
                <br />
                <router-view />
            </el-main>
    </el-container>-->
    <Tabbar />

    <!-- 修改用户信息弹窗 -->
    <el-dialog
      class="updateInfo"
      title="修改信息"
      :visible.sync="dialogVisible"
      width="58%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="修改登陆密码" name="pwd">
          <span>
            <el-input v-model="loginPassword" placeholder="用于pc.mools.net和vr登陆"></el-input>
            <br />
            <br />
            <el-input v-model="loginPassword_replay" placeholder="请再输入一次登录密码" type="password"></el-input>
          </span>
        </el-tab-pane>
        <el-tab-pane label="修改个人信息" name="info">
          <vue-ueditor-wrap v-model="desc" :config="myConfig" @ready="ready"></vue-ueditor-wrap>
        </el-tab-pane>
        <!-- <el-tab-pane label="修改安全密码" name="safe">
                    <span>
                        <el-input v-model="safePassword" placeholder="请输入新的安全密码" ></el-input>
                        <br><br>
                        <el-input v-model="safePassword_replay" placeholder="请再输入一次安全密码" type="password"></el-input>
                    </span>
        </el-tab-pane>-->
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="activeName == 'pwd'" @click="updatePassword">确 定</el-button>
        <el-button type="primary" v-else @click="updateDeveloperData">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import NavMenu from "@/components/NavMenu";
import Tabbar from "@/components/tabbar";
import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
// import Message from "@/components/message";
import {
  getCenterMenuList,
  getTeacherMenuList,
  getStudentMenuList,
  getCerterMenuList,
  getAdminMenuList,
  getSafeAdminMenuList,
  getdeveloperMenuList,
  getSchoolAdminMenuList,
  getDYMenuList,
} from "@/api/menu.js";

import { get, post } from "@/request/http";
import API from "@/api/home";
export default {
  components: {
    NavMenu,
    Tabbar,
    VueUeditorWrap
  },
  data() 
  {
    return {
      // 用户个人信息
      desc: "",
      showmanagerNav: false,
      // activeNames: ['1'],
      // 面包屑列表
      menu: [],
      // 菜单列表
      menuData: [],
      // 默认项
      defaultActive: "teacher/calendar",
      // 用户信息
      userinfo: {},
      // 修改用户信息弹窗标识
      dialogVisible: false,
      activeName: "pwd",
      // 登录密码
      loginPassword: "",
      // 再次输入登录密码
      loginPassword_replay: "",
      // 安全密码
      safePassword: "",
      // 再次输入安全密码
      safePassword_replay: "",
      // 控制是否显示退出按钮
      exit: false,
      // 是否隐藏左侧菜单栏
      hideMenu: this.$route.query.hideMenu,
      // 是否有返回按钮
      type: this.$route.query.type,
      // // 开放日程上课日期
      // date:this.$route.query.date,
      // // 开放课程上课时间
      // time:this.$route.query.time,
      // 开放单元名称
      open_name: "",
      // ueditor 编辑器配置项
      myConfig: {
        // 控制添加题目框的显示与隐藏
        addshow: true,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 初始容器高度
        initialFrameHeight: 300,
        // 你的UEditor资源存放的路径,相对于打包后的index.html
        UEDITOR_HOME_URL: "./Editor/",
        // 编辑器不自动被内容撑高
        // autoHeightEnabled: false,
        // 如果需要上传功能,找后端小伙伴要服务器接口地址
        serverUrl: editorUploadUrl,
        // 关闭自动保存
        enableAutoSave: false,
        // 自定义工具栏，需要额外选项可以参考ueditor.config.js
        toolbars: [
          [
            "inserttable", //表格
            "source", //源代码
            "bold", //加粗
            "italic", //斜体
            "underline", //下划线
            "strikethrough", //删除线
            "fontborder", //字符边框
            "blockquote", //引用
            "selectall", //全选
            "horizontal", //分隔线
            "removeformat", //清除格式
            "unlink", //取消链接
            "deletecaption", //删除表格标题
            "inserttitle", //插入标题
            "cleardoc", //清空文档
            "insertcode", //代码语言
            "fontfamily", //字体
            "fontsize", //字号
            "insertimage", //多图上传
            "link", //超链接
            "emotion", //表情
            "spechars", //特殊字符
            "searchreplace", //查询替换
            "insertvideo", //视频
            "justifyleft", //居左对齐
            "justifyright", //居右对齐
            "justifycenter", //居中对齐
            "forecolor", //字体颜色
            "insertorderedlist", //有序列表
            "insertunorderedlist", //无序列表
            "imageleft", //左浮动
            "imageright", //右浮动
            "attachment", //附件
            "imagecenter", //居中
            "lineheight" //行间距
          ]
        ]
      }
    };
  },
  created() 
  {
    // 获取用户信息显示
    this.getUserInfo();
    // this.getDeveloperData();
    // this.hideMenu = this.$route.query.hideMenu;
    this.open_name = this.$route.query.name;
    let path = this.$route.path;
    if (
      path == "/teacher/calendar" ||
      path == "/teacher/course" ||
      path == "/teacher/projectList" ||
      path == "/teacher/groupList" ||
      path == "/teacher/questionBank" ||
      path == "/teacher/articleList" ||
      path == "/teacher/report/reportTemplate" ||
      path == "/teacher/reportTemplateFile" ||
      path == "/teacher/report/preview" ||
      path == "/teacher/other/navigation" ||
      path == "/teacher/other/colleague" ||
      path == "/teacher/other/searchScore" ||
      path == "/teacher/center/webPageContent" ||
      path == "/teacher/center/webNewsList" ||
      path == "/teacher/center/webNoticeList" ||
      path == "/teacher/center/webNoticeUpdate" ||
      path == "/teacher/center/webNewsUpdate" ||
      path == "/student/reportList" ||
      path == "/manager/logList" ||
      path == "/manager/schoolManager" ||
      path == "/manager/editorWeb" ||
      path == "/manager/editWebDetail" ||
      path == "/manager/expManager" ||
      path == "/manager/labManager" ||
      path == "/teacher/developer/personalInfo" ||
      path == "/teacher/developer/virtualExp" ||
      path == "/manager/exp/developerList" ||
      path == "/manager/expDetail" ||
      path == "/manager/loginTeacher" ||
      path == "/manager/exportgameData" ||
      path == "/manager/batchCreate" ||
      path == "/manager/sendallTeacher" ||
      path == "/manager/user" ||
      path == "/manager/userInfo" ||
      path == "/manager/user/grantProject" ||
      path == "/manager/user/lockProject" ||
      path == "/manager/cert" ||
      path == "/manager/feedBack" ||
      path == "/manager/feedBack/replay" ||
      path == "/manager/keyWords" ||
      path == "/manager/trialApplication"||
      path == "/manager/task"||
      path == "/manager/exportExamPage"||
      path == "/manager/center"||
      path == "/manager/taskDetail" ||
      path == "/teacher/developer/feedBack" ||
      path == "/teacher/other/workStatPage" ||
      path == "/teacher/center/openList" ||
      path == "/teacher/center/courseList" ||
      path == "/teacher/center/memberList" || 
      path == "/manager/apply" ||
      path == "/manager/userRepeat"  ||
      path == "/manager/exportPDF"    || 
      path == "/manager/managerExpStat"        
    ) 
    {
      this.showmanagerNav = true;
    } 
    else 
    {
      this.showmanagerNav = false;
    }
  },
  methods: 
  {
    /**
     * @name: 生成报告PDF
     * @author: camellia
     * @date: 2024-02-07
     */
    createReportPDF()
    {
      let self = this;
      // 请求参数
      let data = {
        start:0,
        limit:50
      };
      // 删除同事请求
      self.$confirm('确认生成报告PDF嘛？共25条，时间较长，可能要稍等一下~', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        self.$Loading.show();
        API.createPDF(data)
        .then((result)=>{
            if(result.code > 0 )
            {
              this.$Tips({
                  // 消息提示内容
                  message:result.msg,
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType:'success',
                  displayTime:3000
              })
            }
            self.$Loading.hide();
        })  
      }).catch(() => {
          Message.info('已取消');        
      });
    },
    /**
     * @name: 修改开发者信息
     * @author: LXY
     * @date: 2022-10-31 10:13:50
     */
    updateDeveloperData() 
    {
      let self = this;
      let data = {
        desc: self.desc
      };
      API.updateDeveloperData(data).then(res => {
        if (res.code > 0) 
        {
          self.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: "success",
            displayTime: 1500
          });
        } 
        else 
        {
          self.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: "warning",
            displayTime: 1500
          });
        }
      });
    },
    /**
     * @name: 获取开发者信息
     * @author: LXY
     * @date: 2022-10-31 10:13:50
     */
    getDeveloperData() 
    {
      let self = this;
      let data = {};
      self.$Loading.show();
      API.getDeveloperData(data).then(res => {
        self.$Loading.hide();
        if (res.code > 0) 
        {
          self.desc = res.desc;
        } 
        else 
        {
          self.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: "warning",
            displayTime: 1500
          });
        }
      });
    },
    handleChange(val) 
    {
      // console.log(val);
    },
    isExit() 
    {
      let self = this;
      self.exit = !self.exit;
    },
    /**
     * @name: 获取editor示例
     * @author: camellia
     * @date: 2021-01-18 10:13:50
     */
    ready(editorInstance) 
    {
      var self = this;
      editorInstance.focus();
      var str = "exam_paper_" + self.bank_id;
      // 添加自定义参数
      editorInstance.execCommand("serverparam", function(editor) {
        return {
          articleid: str //设置富文本编辑器请求时，携带的额外参数
        };
      });
    },
    /**
     * @name: 更改密码
     * @author: camellia
     * @date: 2021-05-08
     */
    updatePassword() 
    {
      var self = this;
      // 请求参数
      let data = {
        password:
          self.activeName == "pwd" ? self.loginPassword : self.safePassword,
        sign: self.activeName
      };
      const regExt = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,}$/;
      if (
        self.activeName == "pwd" &&
        self.loginPassword != self.loginPassword_replay
      ) 
      {
        // Message.error('两次输入密码不一致！');
        this.$Tips({
          // 消息提示内容
          message: "两次输入密码不一致",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: "warning",
          button: [
            {
              type: 1,
              text: "知道了"
            }
          ]
        });
        return;
      }
      if (
        self.activeName == "safe" &&
        self.safePassword != self.safePassword_replay
      ) 
      {
        // Message.error('两次输入密码不一致！');
        this.$Tips({
          // 消息提示内容
          message: "两次输入密码不一致",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: "warning",
          button: [
            {
              type: 1,
              text: "知道了"
            }
          ]
        });
        return;
      }

      if (self.activeName == "safe" && !self.safePassword) 
      {
        // Message.error('密码不能为空');
        this.$Tips({
          // 消息提示内容
          message: "密码不能为空",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: "warning",
          button: [
            {
              type: 1,
              text: "知道了"
            }
          ]
        });
        return;
      }
      if (self.activeName == "pwd" && !regExt.test(self.loginPassword)) 
      {
        // Message.error('长度至少为8,包含大小写字母、数字和特殊字符');
        this.$Tips({
          // 消息提示内容
          message: "长度至少为8,包含大小写字母、数字和特殊字符",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: "warning",
          button: [
            {
              type: 1,
              text: "知道了"
            }
          ]
        });
        return;
      }
      // 发起更改密码请求
      API.updatePassword(data).then(res => {
        if (res.code > 0) {
          // Message.success(res.msg);
          self.dialogVisible = false;
          self.loginPassword = "";
          self.loginPassword_replay = "";
          self.safePassword = "";
          self.safePassword_replay = "";
          self.activeName = "pwd";
          this.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: "success",
            displayTime: 1500
          });
        } 
        else 
        {
          // Message.error('请稍后再试');
          this.$Tips({
            // 消息提示内容
            message: "请稍后再试",
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: "warning",
            button: [
              {
                type: 1,
                text: "知道了"
              }
            ]
          });
          return;
        }
      });
    },
    /**
     * @name: 设置用户信息弹窗
     * @author: camellia
     * @date: 2021-05-08
     */
    setPersonalInfo() 
    {
      this.dialogVisible = true;
      // this.$nextTick(()=>{
      //     const uploadImg = document.querySelector('.edui-default .edui-dialog')
      //     console.log(uploadImg);
      // })
    },
    /**
     * @name: 获取用户信息显示
     * @author: camellia
     * @date: 2021-03-27
     */
    getUserInfo() 
    {
      const self = this;
      let data = {};
      self.axios
        .get("/index.php/backbase/getPublicShowData", {
          params: data
        })
        .then(result => {
          self.userinfo = result.data.array;
          if (self.userinfo.username == "杨雅新") 
          {
            getCerterMenuList().then(res => {
              this.menuData = res.data;
              // 设置默认面包屑
              const path = this.$route.path.split("/");
              path.shift();
              path.shift();
              this.setMenu(path);
              // 设置默认项
              // this.defaultActive = path.pop();
              this.defaultActive = this.$route.path.substr(1);
            });
          } 
          else if (self.userinfo.userType == 6) 
          {
            getDYMenuList().then(res => {
              this.menuData = res.data;
              // console.log(res)
              // 设置默认面包屑
              const path = this.$route.path.split("/");
              path.shift();
              path.shift();
              this.setMenu(path);
              // 设置默认项
              this.defaultActive = this.$route.path.substr(1);
            });
          } 
          else if (self.userinfo.userType == 7) 
          {
            getSchoolAdminMenuList().then(res => {
              this.menuData = res.data;
              // console.log(res)
              // 设置默认面包屑
              const path = this.$route.path.split("/");
              path.shift();
              path.shift();
              this.setMenu(path);
              // 设置默认项
              this.defaultActive = this.$route.path.substr(1);
            });
          } 
          else if (self.userinfo.userType == 1) 
          {
            // 教师
            if (self.userinfo.center_name) 
            {
              getCenterMenuList().then(res => {
                this.menuData = res.data;
                // 设置默认面包屑
                const path = this.$route.path.split("/");
                path.shift();
                path.shift();
                this.setMenu(path);
                // 设置默认项
                // this.defaultActive = path.pop();
                this.defaultActive = this.$route.path.substr(1);
              });
            } 
            else 
            {
              getTeacherMenuList().then(res => {
                this.menuData = res.data;
                // 设置默认面包屑
                const path = this.$route.path.split("/");
                path.shift();
                path.shift();
                this.setMenu(path);
                // 设置默认项
                // this.defaultActive = path.pop();
                this.defaultActive = this.$route.path.substr(1);
              });
            }
          } 
          else if (self.userinfo.userType == 8 ) 
          {
            // 获取菜单列表
            getSafeAdminMenuList().then(res => {
              this.menuData = res.data;
              // 设置默认面包屑
              const path = this.$route.path.split("/");
              path.shift();
              path.shift();
              this.setMenu(path);
              // 设置默认项
              // this.defaultActive = path.pop();
              this.defaultActive = this.$route.path.substr(1);
            });
          } 
          else if (self.userinfo.is_developer == 1) 
          {
            // 获取菜单列表
            getdeveloperMenuList().then(res => {
              this.menuData = res.data;
              // 设置默认面包屑
              const path = this.$route.path.split("/");
              path.shift();
              path.shift();
              this.setMenu(path);
              // 设置默认项
              // this.defaultActive = path.pop();
              this.defaultActive = this.$route.path.substr(1);
            });
          } 
          else if (self.userinfo.userType == 9) 
          {
            getAdminMenuList().then(res => {
              this.menuData = res.data;
              // 设置默认面包屑
              const path = this.$route.path.split("/");
              path.shift();
              path.shift();
              this.setMenu(path);
              // 设置默认项
              this.defaultActive = this.$route.path.substr(1);
            });
          } //*/
          else 
          {
            // 获取菜单列表
            getStudentMenuList().then(res => {
              this.menuData = res.data;
              // 设置默认面包屑
              const path = this.$route.path.split("/");
              path.shift();
              path.shift();
              this.setMenu(path);
              // 设置默认项
              // this.defaultActive = path.pop();
              this.defaultActive = this.$route.path.substr(1);
            });
          }
        })
        .catch(err => {
          // console.log(err);
          // this.getUserInfo()
        });
    },
    /**
     * @name: 退出登录
     * @author: camellia
     * @date: 2020-12-28 15:27:55
     */
    logout() 
    {
      const self = this;
      // 调用公共请求接口
      const logout = data => get("/index.php/backlogin/logout", data);
      logout().then(result => {
        // Message.success(result.msg);
        self.$router.push("/login");
      });
    },
    // 设置menu
    setMenu(list) 
    {
      let tree = this.menuData;
      this.menu = [];
      // 遍历被点击的菜单的路径
      list.forEach(item => {
        // 遍历菜单列表
        tree.forEach(treeItem => {
          // 找到对应菜单
          if (item == treeItem.path) {
            // 将对应菜单节点信息添加到列表中
            this.menu.push(treeItem);
            // 加深一层树
            tree = treeItem.childs;
          }
        });
      });
    },
    // 处理菜单点击事件
    handleSelect(a, b) 
    {
      this.setMenu(b);
      // 跳转到指定位置
      this.$router.push("/" + b.join("/"));
    }
  }
};
</script>

<style lang="scss">
.schoolHead{
  display: flex;
  align-items: center;
  .school_text{
    color: #0D5570;
  }
}
.schoolLogo{
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
}
.layout #edui1_elementpath {
  display: none !important;
}
.userInfo:hover > .exit {
  display: block !important;
}
.userInfo:hover > .el-icon-arrow-down {
  display: none !important;
}
.exit:hover {
  display: block !important;
}
.layout .el-dialog__body{
  // padding: 15px 20px !important;
  padding: 30px 20px !important;
}
.layout .el-dialog__body .is-active {
  border: 0 !important;
}
.layout .el-collapse-item__wrap {
  will-change: height;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 0 !important;
}
.layout .el-collapse {
  position: absolute;
  width: 91%;
  padding: 0.2vw 1vw;
  top: 2.5417vw;
  z-index: 99999;
  border-top: 0 !important;
  border-bottom: 0 !important;
  box-shadow: 0.0521vw 0.091vw 0.1563vw #00000029;
  background-color: #ffffff;
}
.layout .el-collapse-item__header {
  width: 8vw;
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  background-color: #fff;
  color: #0d5570;
  cursor: pointer;
  border-bottom: 0 !important;
  font-size: 13px;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
  margin: auto;
}
.layout .el-collapse-item__content {
  height: 2vw !important;
  /* padding-bottom: 25px; */
  font-size: 0.8333vw;
  color: #303133;
}
.opendiv .is-active {
  border-left: 0.0521vw solid #409eff !important;
}
// .el-icon-arrow-down:hover~.el-icon-arrow-up{
//     display: inline-block !important;
// }
// .el-icon-arrow-down:hover{
//     display: none !important;
// }
.userInfo:hover > .el-icon-arrow-up {
  display: inline-block !important;
}
// .userInfo:hover>.el-icon-arrow-down{
//     display: none !important;
// }
.el-icon-arrow-up {
  display: none;
}
.layout .el-menu-item {
  // font-size: 1.0417vw;
  // line-height: 100%;
  font: normal normal normal 1.0417vw Source Han Sans CN;
  line-height: 2.6042vw;
  // color: #707070;
  padding-left: 2.1875vw !important;
  // border-left: 0.5208vw solid red;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}
.layout .el-aside .navMenu .is-active {
  // padding: 0 !important;
  // border: 0 !important;
  background-color: #e5ecf1;
  border-left: 0.3125vw solid #0d5570;
}
.layout .is-active {
  //  border-left: 0.3125vw solid #0D5570 ;
  //  background-color: red;
  //  padding-left:1.875vw !important;
}
.layout .is-opened {
  padding: 0 !important;
  border: 0 !important;
}
.el-submenu {
  padding: 0 !important;
  border: 0 !important;
}
.layout .el-submenu__title {
  padding-left: 2.1875vw !important;
  font-size: 1.0417vw;
}
.layout .el-menu-item {
  // height: 2.6042vw;
  // line-height: 2.6042vw;
  // padding: 0 2.3438vw;
  /* min-width: 10.4167vw; */
  // min-width: 0vw;
}
.layout .el-collapse-item__arrow {
  margin: 0 !important;
  transition: transform 0.3s;
  font-weight: 300;
}
.layout .updateInfo .edui-default .edui-editor {
  max-height: 30vh;
  border: 1px solid #d4d4d4;
  background-color: white;
  position: relative;
  overflow: scroll;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.el-collapse-item__header {
  height: 1vw !important;
}
// 自定义loading样式
.layout .create-isLoading {
  .el-loading-spinner {
    top: 50% !important;
    left: 50% !important;
    margin-left: -2.8646vw !important;
    background: rgba(0, 0, 0, 0.7) !important;
    padding: 1.0417vw !important;
    border-radius: 0.2083vw !important;
    width: auto !important;
    text-align: center !important;
    position: absolute !important;

    i {
      color: #eee !important;
    }

    .el-loading-text {
      color: #eee !important;
    }
  }
}
.updateInfo .edui-default {
  z-index: 2195 !important;
}
.updateInfo .edui-default .edui-dialog {
  z-index: 2195 !important;
  position: fixed !important;
}
.edui-default .edui-dialog {
  z-index: 2195 !important;
}
#edui_fixedlayer{
  z-index: 2195 !important;
}
</style>

<style lang="scss" scoped>
.el-main {
  background-color: #ecf0f5;
}
$ml-header-height: 3.3333vw;
.logout {
  width: 4.375vw;
  height: 2.1875vw;
  text-align: center;
  box-shadow: 0vw 0vw 0.3125vw #0d557029;
  border-radius: 0.2083vw;

  font: normal normal normal 0.9375vw Source Han Sans CN;
  line-height: 0.3125vw;
  float: right;
  margin-top: 0.5208vw;
}
.userAvator,
.userInfo {
  display: inline-block;
}
.userAvator {
  cursor: pointer;
  height: 2.4479vw;
}
.userInfo {
  height: 3.0729vw;
  margin-left: 0.8333vw;
  padding-top: 0.7813vw;
  vertical-align: bottom;
  font: normal normal normal 0.9375vw Source Han Sans CN;
  letter-spacing: 0vw;
  color: #707070;
}
.exit {
  display: none;
  position: absolute;
  top: 2.1875vw;
  right: 1.4583vw;
  z-index: 2;
}
.info {
  margin-top: 0.0521vw;
  margin-right: 1.0417vw;
  display: inline-block;
}
.showbackButton {
  margin-left: 8.3vw;
}
.layout {
  height: 100%;
  .header-name {
    width: 75%;
    font: normal normal normal 1.1458vw Source Han Sans CN;
    letter-spacing: 0vw;
    color: #707070;
    float: left;
    line-height: 2.8646vw;
    margin-top: 6px;
  }
  .header-name-manager {
    font: normal normal normal 1.1458vw Source Han Sans CN;
    letter-spacing: 0vw;
    color: #707070;
    float: left;
  }
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .header-logo {
    float: left;
    line-height: 4.6875vw;
  }
  .personal-info-div {
    text-align: center;
    font-size: 0.7292vw;
    margin-bottom: 0.7813vw;
  }
  .personal-logo {
    width: 1.6667vw;
    height: 1.6667vw;
    border-radius: 50%;
    margin: auto;
  }
  .personal-logo-div {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 0.5208vw;
    cursor: pointer;
  }

  .width110 {
    width: 5.7292vw;
  }
  .width40 {
    width: 2.0833vw;
  }
  .company-logo-div {
    text-align: center;
    margin-top: 0.4688vw;
    margin-bottom: 1.9792vw;
  }
  .personal-header-border {
    margin-bottom: -1.4vh;
    border-bottom: 0.0521vw solid #fff;
  }
  .el-header {
    height: 3.5417vw !important;
    background-color: #fff;
    box-shadow: 0vw 0.1042vw 0.1042vw #00000029;
    padding: 0.3125vw 1.6667vw;
  }

  .el-menu {
    height: 100%;
  }

  .el-aside {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .el-aside::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .ml-nav-right {
    width: 5.4688vw;
    float: right;

    a {
      display: inline-block;

      &:not(:first-child) {
        margin-left: 0.3646vw;

        &::before {
          content: "";
          display: inline-block;
          position: relative;
          top: 0.1042vw;
          width: 0.0521vw;
          height: 0.7292vw;
          margin-right: 0.3646vw;
          background-color: #fff;
        }
      }
    }
  }
}
.operatorItem {
    cursor: pointer;
    margin-right: 1vw !important;
    height: 2.0833vw;
    text-align: center;
    font-size: 0.8333vw;
    line-height: 2.0833vw;
    letter-spacing: 0vw;
    color: #0D5570;
    opacity: 1;
    border-bottom: 0 !important;
}
</style>
